import { activityTimerName } from "#app/_ui/components/headers/autoLogoff/AutoLogoff";
import { resetTimer } from "#app/lib/activityTimer";


export type message = {
    EventType: String;
}

export type iframeMessage = {
    message: message;

}

export function bindIcFunctions(origin: string) {
    window.iFrameResize({ 
        resizeFrom: 'child', 
        heightCalculationMethod: 'documentElementOffset', 
        checkOrigin: [origin],
        log: false,
        scrolling: false, 
        onMessage: function(event:iframeMessage) {
            if (event.message.EventType == "KEEP_ALIVE") {
                resetTimer(activityTimerName, {});
            }
        }
    }); 
}